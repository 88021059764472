import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { messagingIntegrationsGuardLogic } from '@core/guards/messaging-integrations-logic';
import { MessagingIntegrationsService } from '@core/services/third-party-integrations/messaging-integrations.service';
import { SOCIAL_ANALYTICS_SLUGS } from '@features/social-analysis/social-analytics.routes';
import { APP_SLUGS, MAIN_SLUGS } from '@routes/routes.types';

export const socialAnalyticsGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const integrationsS = inject(MessagingIntegrationsService);
    const redirectUrl = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.SOCIAL_ANALYTICS}/${SOCIAL_ANALYTICS_SLUGS.connect}`;

    return messagingIntegrationsGuardLogic(
        integrationsS.isAnyChannelConnected,
        state.url,
        redirectUrl
    );
};
